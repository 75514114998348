// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bW_kr";
export var caseStudyBackground__bgColor = "bW_kn";
export var caseStudyBackground__lineColor = "bW_kp";
export var caseStudyHead__imageAbsolute = "bW_kl";
export var caseStudyHead__imageWrapper = "bW_kk";
export var caseStudyProjectsSection = "bW_ks";
export var caseStudyQuote__bgRing = "bW_km";
export var caseStudyTech = "bW_kF";
export var caseStudyVideo = "bW_kB";
export var caseStudyVideo__ring = "bW_kv";
export var caseStudy__bgDark = "bW_kh";
export var caseStudy__bgLight = "bW_kg";